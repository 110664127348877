import axios from 'axios';

const baseUrl = "https://api.qorbiworld.com"
// const localUrl = "http://localhost:4001"

export const getQorbiUser = async (identifier) => {
    const user = await axios.get(`${baseUrl}/sphere-one/user/passport/${identifier}`)
    return user.data;
};

export const createQorbiUser = async (userData) => {
    const response = await axios.post(`${baseUrl}/sphere-one/add-user`, userData)
    return response;
};

export const updateQorbiUser = async (userData) => {
    const response = await axios.patch(`${baseUrl}/sphere-one/update-user`, userData);
    return response;
};

export const getOpenLootUserData = async (code, userId, redirectUri) => {
    console.log();
    const response = await axios.post(`${baseUrl}/open-loot/get-user-data`, {
        code,
        redirectUri,
        userId,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'accept': '*/*',
        }
    });
    return response.data;
};