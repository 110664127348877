import React, { useContext, useEffect, useState } from "react";
import WebStorageCache from "web-storage-cache";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NavBar from "../components/Header/NavBar";
import Home from "../screens/Home";
import { Footer } from "../components/Footer";
import Lottie from "react-lottie";
import * as animationData from "../assets/lotties/Dot_Loading.json";
import { ImmutablePassport, PlayFab, PopUp, Sphereone, Toast } from "../utils/Global";
import { Context } from "../context/WalletContext";
import HowToBuy from "../screens/HowToBuy";
import Popup from "../components/Popup";
import Mint from "../screens/Mint";
import EggMint from "../screens/EggMint.js";
import SuccessPage from "../screens/SuccessPage"
import FailedPage from "../screens/FailedPage"
import Games from "../screens/Games";
import Qorbi from "../screens/Qorbi";
import ThankYou from "../screens/ThankYou";
import MyAssets from "../screens/MyAssets";
import PlayOneTap from "../screens/PlayOneTap";
import MintForm from "../screens/mintform";
import Market from "../screens/Market";
import PrivateMarket from "../screens/PrivateMarket.js";
import DailyGamers from "../screens/DailyGamers";
import NftDetails from "../screens/NftDetails.js";
import SingleMarket from "../screens/SingleMarket";
import GamerPrizes from "../screens/gamerprizes";
import RegisterWalletOverlay from "../components/RegisterWalletOverlay.js";
import QorX from "../screens/QorX.js";
import { sphereoneSdk } from "../utils/SphereOne";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import LoadingSVG from "../assets/svg/Loading"
import axios from "axios";
import OpenLootBox from "../screens/OpenLootBox.js";
import RegisterForm from "../components/RegisterForm.js";
import QorShop from "../screens/QorShop.js";
import MyBundles from "../screens/MyBundles.js";
import QorbCollection from "../screens/QorbCollection.js";
import ElixirLogs from "../screens/ElixirLogs.js";
import GameLogs from "../screens/GameLogs.js";
import QorbabyConfirmation from "../screens/QorbabyConfirmation.js";
import QorbStaking from "../screens/QorbStaking.js";
import StartMissionsMessage from "../components/StartMission.js";
import SkinTrade from "../screens/SkinTrade.js";
import Bundles from "../screens/Bundles.js";
import EggTrade from "../screens/EggTrade.js";
import BetaTester from "../screens/BetaTester.js";
import UserProfile from "../screens/UserProfile.js";
import BetaCountdown from "../screens/Countdown.js";
import { passportInstance } from "../context/PassportService.js";

const qorbiServices = require('../context/QorbiService');

let isLoggedIn;

const CustomRoute = ({ Component, path, ...rest }) => {
  if (isLoggedIn) {
    return <Component {...rest} />;
  } else {
    localStorage.setItem('unauthorizedPath', path);
    return <Navigate to="/" />;
  }
};

export const Routers = () => {
  const {
    state,
    localLogin,
    playfabUser,
    LoginWalletSphereone,
    immutableLogin,
    metamaskLogin,
  } = useContext(Context);
  const [toast, setToast] = useState({ show: false, type: "", desc: "" });
  const [showPopup, setShowPopup] = useState({});
  const session = localStorage.getItem("session");
  if (session !== null || state.user !== null) {
    isLoggedIn = true;
  }
  const storedItem = localStorage.getItem('unauthorizedPath');
  const [ready, setReady] = useState(false);
  const { setQorbiUserData, setSphereOneUser, sphereOneUser, setSphereOneWallets, setMultipleWallets, setDevicedUsed, setRegisterWallet, setRegisterUser, registerWallet, registerUser, startMission } = useSphereOneProvider();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const cache = new WebStorageCache({ storage: 'localStorage', exp: (60 * 60 * 24) }); // Cache expires in 24 hour
  const deleteTokenValue = cache.get('deleteToken');

  const isIMX = async (address) => {
    try {
      const immutableResponse = await axios.get(`https://api.x.immutable.com/v1/users/${address}`);
      if (immutableResponse.data) {
        return true; // Here we check if the user exists
      }
    } catch (immutableError) {
      return false; // Here, the user needs to register into IMX
    }
  };

  const connectPassport = async () => {
    const provider = passportInstance.connectEvm();
    const accounts = await provider.request({ method: 'eth_requestAccounts' });
    const userProfile = await passportInstance.getUserInfo();
    localStorage.setItem("WALLET_ADDRESS", accounts[0]);
    try {
      //Validate is the user with that wallet already exists, else create it
      const emailUser = await qorbiServices.getQorbiUser(userProfile.email);
      const walletObject = {
        address: accounts[0]
      }
      setQorbiUserData(emailUser)
      LoginWalletSphereone(walletObject, accounts);
    } catch (sphereOneError) {      
      setRegisterUser(true);
    }
    setReady(true)
  }

  const validateWallet = async (address) => {
    const isImx = await isIMX(address)
    if (isImx) {
      try {//Validate is the user with that wallet already exists, else create it
        const walletUser = await qorbiServices.getQorbiUser(address);
        setQorbiUserData(walletUser)
      } catch (sphereOneError) {
        setRegisterUser(true);
      }
    } else {
      setRegisterWallet({ show: true, wallet: address })
    }
  };

  useEffect(() => {
    Toast.get().subscribe((res) => {
      setToast({ show: res.show, type: res.type, desc: res.desc });
      if (res.show) {
        setTimeout(() => {
          Toast.set({ show: false, type: "", desc: "" });
        }, 1000000);
      }
    });
    // check if user already has session with playfab if not try sphereone
    if (session) {
      let sessionObj = JSON.parse(session);
      sessionObj.authType = PlayFab;
      localLogin(sessionObj);
      playfabUser(sessionObj.PlayFabId);
      setReady(true);
    } else {
      const userInfoString = localStorage.getItem('userInfo');
      if (userInfoString) {
        const userInfo = JSON.parse(userInfoString);
        localLogin(userInfo);
        if (userInfo.authType === ImmutablePassport) {
          connectPassport()
        } else {
          validateWallet(userInfo.data)
          metamaskLogin()
          setReady(true);
        }
      } else {
        loginSphereOne();
      }
    }
    PopUp.get().subscribe((res) => {
      setShowPopup(res);
    });

    if (isMobile) {
      if (!deleteTokenValue) {
        cache.set('deleteToken', 'true');
      }
      setDevicedUsed('mobile')
    } else {
      setDevicedUsed('computer')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUser = async (uid, email, wallet) => {
    const apiUrl = `https://api.qorbiworld.com/sphere-one/user/sphereone/${uid}`;
    await axios
      .get(apiUrl)
      .then((res) => {
        setQorbiUserData(res.data)
        setSphereOneUser({ ...sphereOneUser, username: res.data.username });
        // Check if walletId is missing or empty and update it
        if (!res.data.walletId || res.data.walletId === '') {
          console.log('HERE');

          qorbiServices.updateQorbiUser({
            walletId: wallet,
            username: res.data.username,
          })
        }
      })
      .catch((error) => {
        console.error(error);
        const getDataByEmail = async () => {
          try {
            const emailUserData = await qorbiServices.getQorbiUser(email);
            // Check if walletId is missing or empty and update it
            if (!emailUserData.walletId || emailUserData.walletId === '') {
              qorbiServices.updateQorbiUser({
                walletId: wallet,
                username: emailUserData.username,
              })
            }
            qorbiServices.updateQorbiUser({
              username: emailUserData.username,
              sphereOneId: uid
            });
            setQorbiUserData(emailUserData)
            setSphereOneUser({ ...sphereOneUser, username: emailUserData.username });
          } catch (error) {
            setRegisterUser(true);
          }
        }
        getDataByEmail();
      });
  };

  const deleteLocalStorageItemByPartialKey = (partialKey) => {
    // Iterate over all keys in localStorage to find the SphereOne token item that causes the problem
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.includes(partialKey)) {
        // Remove the item from localStorage but before we check if this was done before
        if (deleteTokenValue === 'true') {
          localStorage.removeItem(key);
          cache.set('deleteToken', 'false');
          // Reload the page after deleting the item to reload the navbar resource that was affected
          window.location.reload();
        }
      }
    }
  }
  const connectImmutable = async () => {
    if (localStorage.getItem("WALLET_ADDRESS")) {
      localStorage.removeItem("STARK_PUBLIC_KEY");
      localStorage.removeItem("WALLET_ADDRESS");
    }
    try {
      if ("ethereum" in window) {
        const userAddress = await immutableLogin();
        if (userAddress.data) {
          setRegisterWallet({ show: false, wallet: userAddress.data })
          window.location.reload();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const loginSphereOne = async () => {
    await sphereoneSdk
      .handleCallback()
      .then(async (authResult) => {
        if (authResult.access_token) {
          const userInfo = await sphereoneSdk.getUserInfo({
            forceRefresh: false,
          });
          setSphereOneUser(userInfo)
          const walletsSO = await sphereoneSdk.getWallets();
          const immutableWallets = walletsSO.filter(
            wallet => wallet.chains.includes('IMMUTABLE')
          );
          const ownerAddress = immutableWallets?.find((wallet) => !wallet.isImported);
          checkUser(userInfo.uid, userInfo.email, ownerAddress.address)
          userInfo.authType = Sphereone;
          localLogin(userInfo);
          setSphereOneWallets(immutableWallets)
          if (immutableWallets.length > 1) {
            const loginMethodWallet = immutableWallets.find(wallet => wallet.label === "Login Wallet" && wallet.isImported === true);
            if (loginMethodWallet) {
              await axios.get(`https://api.x.immutable.com/v1/users/${loginMethodWallet.address}`)
                .then()
                .catch(error => {
                  //Here, he needs to register into IMX
                  setRegisterWallet({ show: true, wallet: loginMethodWallet.address })
                });
            }
            setMultipleWallets(true)
            LoginWalletSphereone(immutableWallets.filter(wallet => wallet.isImported === false)[0], immutableWallets);
          } else {
            LoginWalletSphereone(immutableWallets[0], immutableWallets);
          }
        }
      })
      .catch((e) => {
        console.error("Login Into SphereOne Failed", e);
        if (isMobile) {
          deleteLocalStorageItemByPartialKey("oidc.user:https://auth.sphereone.xyz:") // This should only be called if the login fails in a phone, so no session should be affected... 
        }
      });
    setReady(true);
  };
  const loadingOpt = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return ready ? (
    <div>
      {registerWallet.show && <RegisterWalletOverlay registerImmutable={connectImmutable} unregisteredWallet={registerWallet.wallet}></RegisterWalletOverlay>}
      {registerUser && <RegisterForm></RegisterForm>}
      {startMission && <StartMissionsMessage></StartMissionsMessage>}
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<HowToBuy />} />{/*Refactor to make into instruction page*/}
          <Route path="/qorbi" element={<Qorbi />} />
          <Route path="/" element={<DailyGamers />} />{/*Refactor to make into matches history page*/}
          <Route path="/" element={<GamerPrizes />} /> {/*Refactor to make into rules page*/}
          <Route path="/market" element={<Market />} />
          <Route path="/qorshop" element={<QorShop />} />
          <Route path="/bundles" element={<Bundles />} />
          <Route path="/failed" element={<FailedPage />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/elixir" element={<ElixirLogs />} />
          <Route path="/gamelogs" element={<GameLogs />} />
          <Route path="/my-assets" element={<MyAssets />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/single/:id" element={<SingleMarket />} />
          <Route path="/my-bundles/:id" element={<MyBundles />} />
          <Route path="/private-market" element={<PrivateMarket />} />
          <Route path="/qorb-collection" element={<QorbCollection />} />
          <Route path="/close-beta-countdown" element={<BetaCountdown />} />
          <Route path="/qorbaby-confirmation" element={<QorbabyConfirmation />} />
          <Route path="/nft-details/:collection/:token_id" element={<NftDetails />} />
          {/* Custom Routes */}
          <Route path="/download" element={<Navigate to="/" replace />} />
          <Route path="/mint" element={<CustomRoute path="/mint" Component={Mint} />} />
          <Route path="/qorx" element={<CustomRoute path="/qorx" Component={QorX} />} />
          <Route path="/eggmint" element={<CustomRoute path="/eggmint" Component={EggMint} />} />
          <Route path="/kitchen" element={<CustomRoute path="/kitchen" Component={EggTrade} />} />
          <Route path="/mintform" element={<CustomRoute path="/mintform" Component={MintForm} />} />
          <Route path="/skin-forge" element={<CustomRoute path="/skin-forge" Component={SkinTrade} />} />
          <Route path="/beta-tester" element={<CustomRoute path="/beta-tester" Component={BetaTester} />} />
          <Route path="/openlootbox" element={<CustomRoute path="/openlootbox" Component={OpenLootBox} />} />
          <Route path="/play-one-tap" element={<CustomRoute path="/play-one-tap" Component={PlayOneTap} />} />
          <Route path="/qorb-staking" element={<CustomRoute path="/qorb-staking" Component={QorbStaking} />} />
          {/* Main Route */}
          <Route
            path="/games"
            element={
              storedItem === null ? (
                <Games />
              ) : (
                (() => {
                  if ((window.location.pathname === "/games" || window.location.pathname === "/") && (isLoggedIn)) {
                    return <Navigate to={storedItem} />;
                  } else {
                    return <Games />
                  }
                })()
              )
            } />
        </Routes>
        <Footer />
      </Router>
      {toast.show ? (
        <div className="position-fixed alert-container">
          <div
            className="close"
            onClick={() => Toast.set({ show: false, desc: "", type: "" })}
          />
          <div className="toast-container">
            <div
              className={`toast-icon ${toast.type === "success" ? "toast-success" : "toast-error"
                }`}
            >
              {toast.type === "success" ? (
                <i className="fa fa-check-circle" />
              ) : (
                <i className="fa fa-exclamation-circle" />
              )}
              <h2>{toast.type === "success" ? "Well Done" : "Error"}</h2>
            </div>
            <div className="toast-content">{toast.desc}</div>
            <button
              className="btnTransparent"
              onClick={() => Toast.set({ show: false, desc: "", type: "" })}
            >
              close
            </button>
          </div>
        </div>
      ) : null}
      {state.loading ? (
        <div className="loading-container">
          <Lottie options={loadingOpt} height={90} width={88} />
          {state.loadingText ? (
            <div className="loading-text">{state.loadingText}</div>
          ) : null}
        </div>
      ) : null}
      {showPopup.show ? (
        <Popup
          title={showPopup.title}
          content={showPopup.content}
          onClose={() => PopUp.set({ show: false, title: "", content: null })}
        />
      ) : null}
    </div>
  ) : (
    <div className="loadScreen" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img
        src="/assets/img/White_Full_Logo.png"
        alt="sticky brand-logo"
        width={120}
        style={{ marginBottom: '20px' }}
      />
      <div style={{ textAlign: 'center', color: 'white' }}>
        <h2 style={{ fontSize: '28px', marginBottom: '10px' }}>Welcome to Qorbi World</h2>
        <p style={{ fontSize: '20px', marginBottom: '20px' }}>Loading the guns and fetching the eggs</p>
        <LoadingSVG></LoadingSVG>
        <p style={{ fontSize: '18px', marginTop: '20px' }}>Hang tight, we're getting all ready...</p>
      </div>
    </div>
  );
};
