/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import Success from '../assets/svg/Success'
import Failed from '../assets/svg/Failed'
import axios from 'axios';
import { sphereoneSdk } from "../utils/SphereOne";
import { Context } from "../context/WalletContext";
import { ImmutableXClient } from "@imtbl/imx-sdk";
import { Sphereone } from "../utils/Global";
const QorbabyConfirmation = () => {
    const { state } = useContext(Context);
    const urlParams = new URLSearchParams(window.location.search);
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '0 20px',
    };

    const cardStyle = {
        marginTop: "50px",
        backgroundColor: '#1A1919',
        color: '#000000',
        border: '3px #fff',
        borderRadius: '12%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: '20px',
        boxSizing: 'border-box',
        width: '90%',
        maxWidth: '600px',
    };

    const titleStyle = {
        fontSize: '36px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#fff',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    };

    const imageStyle = {
        width: '75%',
        height: 'auto',
        borderRadius: '8px',
        marginTop: '20px',
    };

    const textStyle = {
        color: '#636363',
        marginTop: '20px',
    };

    const [currentQorb, setCurrentQorb] = useState({})
    const [email, setEmail] = useState("")
    const getEmail = async () => {
        try {
            const userInfo = await sphereoneSdk.getUserInfo({
                forceRefresh: false,
            });
            if (userInfo?.uid) {
                const response = await axios.get(`https://api.qorbiworld.com/sphere-one/user/sphereone/${userInfo.uid}`);
                setEmail(response.data.email)
            } else {
                const response = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${state?.userWallet?.string}`);
                setEmail(response.data.email)
            }
        } catch (error) {
            const response = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${state?.userWallet?.string}`);
            setEmail(response.data.email)
        }
    }
    const confirmOwnership = async (wallet_address) => {
        const client = await ImmutableXClient.build({
            publicApiUrl: "https://api.x.immutable.com/v1",
        });
        if (wallet_address !== undefined) {
            const badgesAssetsRequest = await client.getAssets({
                user: wallet_address,
                collection: "0x938b5c1850106ebc82d49af65a030e1d9083f2f5",
            });
            if (!badgesAssetsRequest.result.length > 0) {
                relocate()
            } else {
                const filteredBadge = badgesAssetsRequest.result.find(badge => badge.token_id === urlParams.get('badge'));
                if (!filteredBadge) {
                    relocate()
                }
            }
        }
    };

    const relocate = () => {
        window.location.href = "/my-assets";
    }

    useEffect(async () => {
        getEmail()
        if (state?.user?.authType === Sphereone) {
            const soWallets = await sphereoneSdk.getWallets()
            const filteredWallet = soWallets.find(wallet => wallet.address.toLowerCase() === urlParams.get('wallet').toLowerCase());
            if (filteredWallet) {//The badge wallet is the one of the user
                setCurrentQorb({
                    id: urlParams.get('id'),
                    name: urlParams.get('name'),
                    image: urlParams.get('image'),
                    badge: urlParams.get('badge'),
                    wallet: urlParams.get('wallet'),
                })
            } else {
                relocate()
            }
        } else {
            if (state?.userWallet?.string === urlParams.get('wallet')) { //The badge wallet is the one of the user
                setCurrentQorb({
                    id: urlParams.get('id'),
                    name: urlParams.get('name'),
                    image: urlParams.get('image'),
                    badge: urlParams.get('badge'),
                    wallet: state.userWallet.string,
                })
            } else {
                relocate()
            }
        }
    }, [])

    const handleConfirmClick = async () => {
        confirmOwnership(currentQorb.wallet)
        try {
            await axios.post(`https://api.qorbiworld.com/v1/nft/create-order-qor`, {
                "walletAddress": currentQorb.wallet,
                "name": currentQorb.name,
                "qorbId": currentQorb.id,
                "tokenBadgeId": currentQorb.badge,
                "email": email,
            });
            alert('Qorb minted successfully!');
            setTimeout(function () {
                relocate()
            }, 5000);
        } catch (error) {
            console.error("Error creating order:", error);
            alert('Error creating order');
        }
    };

    return (
        <div style={containerStyle}>
            <div style={cardStyle}>
                <img
                    src={currentQorb?.image}
                    alt="Selected Qorb"
                    style={imageStyle}
                />
                <h2 style={titleStyle}>Qorb: {currentQorb?.name}</h2>
                <p style={textStyle}>
                    <strong style={{ color: "#fff" }}>Wallet:</strong> {currentQorb?.wallet?.substring(0, 6)}...{currentQorb?.wallet?.substring(currentQorb?.wallet?.length - 5)}
                </p>
                <p style={textStyle}>
                    This is the <a style={{ color: "white" }}>QORB</a> you selected to mint from your <a style={{ color: "white" }}>badge #{currentQorb.badge}</a>. This selection will be <a style={{ color: "white" }}>permanent</a>. To confirm the mint, please click the first button. To select a different a <a style={{ color: "white" }}>QORB</a> hit the second button.
                </p>
                <div>
                    <Success onClick={handleConfirmClick}></Success>
                    <Failed onClick={window.close} style={{ marginBottom: '20px' }}></Failed>
                </div>
            </div>
        </div >
    );
};

export default QorbabyConfirmation;
