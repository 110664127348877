import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const NftDetails = () => {
    const { collection, token_id } = useParams();
    const [nftData, setNftData] = useState(null);
    const [nftTransactions, setNftTransactions] = useState(null);
    const [activeTab, setActiveTab] = useState('details');

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const collectionAddress =
        collection === "egg" ? "0xeea2fe42cc1774a802edfc7a08a35c361827befc" :
            collection === "skin" ? "0x8a2315e441786e10ff1962387407628108b9ba33" :
                collection === "qorb" ? "0x2d59c7b8d253b826cdba9389be397a68af88cf08" :
                    collection === "badge" ? "0x938b5c1850106ebc82d49af65a030e1d9083f2f5" :
                        "unknown";
    const getItemDetails = async () => {
        const apiUrl = `https://api.x.immutable.com/v1/assets/${collectionAddress}/${token_id}`;
        await axios.get(apiUrl)
            .then(response => {
                setNftData(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const fetchOrderDetails = async () => {
        const tradeResponse = await axios.get(`https://api.x.immutable.com/v3/trades?party_b_token_address=${collectionAddress}&party_b_token_id=${token_id}`);
        const tradeOrderDetails = await Promise.all(
            tradeResponse.data.result
                .filter(transaction => transaction.a && transaction.b)
                .map(async (transaction) => {
                    try {
                        const orderDetailsResponseBuyer = await axios.get(`https://api.x.immutable.com/v3/orders/${transaction.a.order_id}`);
                        const orderDetailsResponseSeller = await axios.get(`https://api.x.immutable.com/v3/orders/${transaction.b.order_id}`);

                        return {
                            timestamp: transaction.timestamp,
                            a: orderDetailsResponseBuyer.data,
                            b: orderDetailsResponseSeller.data,
                        };
                    } catch (error) {
                        console.error('Error fetching order details:', error);
                        return null;
                    }
                })
        );

        return tradeOrderDetails.filter(orderDetails => orderDetails !== null);
    };

    const getItemTransactions = async () => {
        try {
            const mintResponse = await axios.get(`https://api.x.immutable.com/v1/mints?order_by=created_at&direction=desc&token_id=${token_id}&token_address=${collectionAddress}`);
            const transferResponse = await axios.get(`https://api.x.immutable.com/v1/transfers?token_id=${token_id}&token_address=${collectionAddress}`);
            // Fetch order details for trade transactions
            const tradeOrderDetails = await fetchOrderDetails();

            // Combine the result arrays
            const combinedData = [
                ...mintResponse.data.result,
                ...transferResponse.data.result,
                ...tradeOrderDetails,
            ];

            // Sort the combined array based on date
            combinedData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setNftTransactions(combinedData);
        } catch (error) {
            console.error(error);
        }
    };

    const capitalizeAndReplaceUnderscore = (str) => {
        return str.replace(/_/g, ' ').replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1));
    };

    const sliceWallet = (str) => {
        if (!isMobile) {
            const firstPart = str.slice(0, 8);
            const lastPart = str.slice(-8);
            return `${firstPart}...${lastPart}`;
        } else {
            const firstPart = str.slice(0, 8);
            return `${firstPart}...`;
        }

    };

    const calculateTimeElapsed = (timestamp) => {
        const now = new Date();
        const transactionTime = new Date(timestamp);
        const timeDifference = now - transactionTime;

        // Calculate elapsed time in seconds, minutes, hours, and days to show in table
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} days ago`;
        } else if (hours > 0) {
            return `${hours} hours ago`;
        } else if (minutes > 0) {
            return `${minutes} minutes ago`;
        } else {
            return `${seconds} seconds ago`;
        }
    };

    const getTabButtonStyle = (tab) => {
        const baseStyle = {
            width: '55px',
            textAlign: 'left',
            fontSize: '18px',
            lineHeight: '21px',
            fontFamily: 'SF UI Display',
            letterSpacing: '0px',
            color: '#A6A6A6',
            opacity: '1',
            border: 'none',
            background: 'transparent',
            outline: 'none',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        };

        const activeStyle = {
            color: '#FFFFFF',
        };
        return activeTab === tab ? { ...baseStyle, ...activeStyle } : { ...baseStyle };
    };
    const tableHeaderStyle = {
        width: "61px",
        height: "21px",
        textAlign: "left",
        fontFamily: 'SF UI Display',
        fontWeight: 'medium',
        font: "normal normal medium 18px / 21px SF UI Display",
        letterSpacing: "0px",
        color: "#38EF7D",
        textTransform: "capitalize",
        opacity: "1",
    };

    const tableCellStyle = {
        width: "150px",
        height: "21px",
        textAlign: "left",
        font: "normal normal medium 18px / 21px SF UI Display",
        letterSpacing: "0px",
        color: "#FFFFFF",
        textTransform: "capitalize",
        opacity: "1",
        fontFamily: 'SF UI Display',
        fontWeight: 'medium',
    };

    const tableRowEvenStyle = {
        background: '#1E1E1E',
    };

    const tableRowOddStyle = {
        background: '#141414',
    };

    const imageStyles = {
        width: '685px',
        background: 'transparent 0% 0% no-repeat padding-box',
        border: '20px solid #31E17F',
        borderRadius: '85px',
        opacity: '1',
    };

    const menuStyles = {
        width: isMobile ? '500px' : '700px',
        height: '50px',
        background: '#1A1919 0% 0% no-repeat padding-box',
        border: '1px solid #FFFFFF0D',
        borderRadius: '45px',
        opacity: '1',
        display: 'flex',
        justifyContent: 'space-around',
        overflowX: 'auto',
        alignItems: 'center',
        marginBottom: '20px',
    };

    const containerStyles = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '50px',
        color: 'white',
        flexDirection: isMobile ? 'column' : 'row',
    };

    const leftSideStyles = {
        marginRight: (!isMobile ? '50px' : '0'),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const rightSideStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const nameStyles = {
        width: '290px',
        height: '48px',
        textAlign: 'left',
        fontSize: '40px',
        lineHeight: '48px',
        letterSpacing: '0px',
        color: '#FFFFFF',
        opacity: '1',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontFamily: 'SF UI Display',
        fontWeight: 'bold',
    };

    useEffect(() => {
        getItemDetails();
        getItemTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDetailsTabContent = () => {
        const metadataItems = nftData.metadata
            ? Object.entries(nftData.metadata)
                .filter(
                    ([key, value]) =>
                        key !== 'image_url' &&
                        value !== null &&
                        key !== 'animation_url_mime_type' &&
                        key !== 'video_url' &&
                        key !== 'animation_url' &&
                        key !== 'description'
                )
                .map(([key, value]) => ({
                    key,
                    label: capitalizeAndReplaceUnderscore(key),
                    value: value.toString(),
                }))
            : [];

        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', width: isMobile ? '500px' : '700px' }}>
                {metadataItems.map(({ key, label, value }) => (
                    <div
                        key={key}
                        style={{
                            background: '#1A1919',
                            border: '1px solid #FFFFFF0D',
                            borderRadius: '35px',
                            padding: '15px',
                            minWidth: '150px',
                            flex: '1 0 auto',
                        }}
                    >
                        <strong
                            style={{
                                width: '38px',
                                height: '21px',
                                textAlign: 'left',
                                fontFamily: 'SF UI Display',
                                fontWeight: 'bold',
                                letterSpacing: '0px',
                                color: '#A6A6A6',
                                textTransform: 'capitalize',
                                opacity: '1',
                            }}
                        >
                            {label}:
                        </strong>
                        <div
                            style={{
                                height: '31px',
                                textAlign: 'left',
                                fontFamily: 'SF UI Display',
                                fontWeight: 'bold',
                                letterSpacing: '0px',
                                color: '#FFFFFF',
                                fontSize: '24px',
                            }}
                        >
                            {value}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderTransactionsTabContent = () => (
        <div>
            {nftTransactions !== null && (
                <table style={{ width: '100%', color: 'white', borderCollapse: 'collapse', borderRadius: '8px', overflow: 'hidden' }}>
                    <thead>
                        <tr>
                            <th style={{ ...tableHeaderStyle, borderTopLeftRadius: '8px' }}>Time</th>
                            <th style={tableHeaderStyle}>Type</th>
                            <th style={tableHeaderStyle}>From</th>
                            <th style={{ ...tableHeaderStyle, borderTopRightRadius: '8px' }}>To</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nftTransactions.map((transaction, index) => (
                            <tr key={index} style={index % 2 === 0 ? tableRowEvenStyle : tableRowOddStyle}>
                                <td style={tableCellStyle} title={new Date(transaction.timestamp).toLocaleString()}>
                                    {calculateTimeElapsed(transaction.timestamp)}
                                </td>
                                <td style={tableCellStyle}>
                                    {transaction.a && transaction.b
                                        ? 'Sell'
                                        : transaction.receiver
                                            ? 'Transfer'
                                            : 'Mint'}
                                </td>
                                <td style={tableCellStyle}>
                                    {transaction.a && transaction.b
                                        ? sliceWallet(transaction.b.user)
                                        : transaction.receiver
                                            ? sliceWallet(transaction.user)
                                            : 'N/A'}
                                </td>
                                <td style={tableCellStyle}>
                                    {transaction.a && transaction.b
                                        ? sliceWallet(transaction.a.user)
                                        : transaction.receiver
                                            ? sliceWallet(transaction.receiver)
                                            : sliceWallet(transaction.user)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );

    const renderPriceHistoryTabContent = () => {
        const tradeTransactions = nftTransactions?.filter(transaction => transaction.a && transaction.b) || [];

        if (tradeTransactions.length === 0) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75px',
                    width: '350px',
                    backgroundColor: '#1bb08a',
                    color: 'white',
                    border: '5px',
                    borderColor: '#fff',
                    borderRadius: '25%',
                    fontFamily: 'SF UI Display',
                    fontWeight: 'bold',
                    fontSize: '22px'

                }}>
                    This item has not been traded yet.
                </div>
            );
        }

        const averagePrice =
            tradeTransactions.length > 0
                ? tradeTransactions.reduce((total, transaction) => total + Number(transaction.a.amount_sold) / 1e18, 0) / tradeTransactions.length
                : 0;

        return (
            <div>
                <div style={{ marginBottom: '10px', color: 'white' }}>
                    <strong>Average Price:</strong> {averagePrice}/ETH
                </div>
                <table style={{ width: '100%', maxWidth: '1200px', color: 'white', borderCollapse: 'collapse', borderRadius: '8px', overflow: 'hidden' }}>
                    <thead>
                        <tr>
                            <th style={{ backgroundColor: '#1bb08a', padding: '10px', borderBottom: '2px solid white' }}>Date</th>
                            <th style={{ backgroundColor: '#1bb08a', padding: '10px', borderBottom: '2px solid white' }}>Buyer</th>
                            <th style={{ backgroundColor: '#1bb08a', padding: '10px', borderBottom: '2px solid white' }}>Price/ETH</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tradeTransactions.map((transaction, index) => (
                            <tr key={index}>
                                <td style={{ padding: '10px', borderBottom: '1px solid white' }} title={new Date(transaction.timestamp).toLocaleString()}>
                                    {new Date(transaction.timestamp).toLocaleDateString()}
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid white' }}>
                                    {transaction.a.user}
                                </td>
                                <td style={{ padding: '10px', borderBottom: '1px solid white' }}>
                                    {(Number(transaction.a.amount_sold) / 1e18)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'details':
                return renderDetailsTabContent();
            case 'transactions':
                return renderTransactionsTabContent();
            case 'priceHistory':
                return renderPriceHistoryTabContent();
            default:
                return null;
        }
    };

    return (
        <div style={{ padding: isMobile ? '10px' : '20px', marginTop: 75 }}>
            {nftData !== null && (
                <div style={{ ...containerStyles }}>
                    {/* Left Side: Image and Name */}
                    <div style={{ ...leftSideStyles }}>
                        <div style={{ ...imageStyles }}>
                            <img src={nftData.image_url} alt={nftData.name} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '60px' }} />
                        </div>
                    </div>
                    {/* Right Side: Grid with NFT Details */}
                    <div style={{ ...rightSideStyles }}>
                        <div style={{ width: isMobile ? '500px' : '700px' }}>
                            <h2 style={nameStyles}>
                                {nftData.name}
                            </h2>
                        </div>
                        {/* Tab Menu */}
                        <div style={menuStyles}>
                            <button
                                style={getTabButtonStyle('details')}
                                onClick={() => setActiveTab('details')}
                            >
                                Details
                            </button>
                            <button
                                style={getTabButtonStyle('transactions')}
                                onClick={() => setActiveTab('transactions')}
                            >
                                Transactions
                            </button>
                            <button
                                style={getTabButtonStyle('priceHistory')}
                                onClick={() => setActiveTab('priceHistory')}
                            >
                                Price History
                            </button>
                        </div>
                        {/* Tab Content */}
                        {renderTabContent()}
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default NftDetails;