import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qorbi_coin from '../assets/img/qorbi-coin.png';
import { ImmutableXClient } from "@imtbl/imx-sdk";
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import { useNavigate } from 'react-router-dom';

const QorbStaking = () => {
    const [qorbStaking, setQorbStaking] = useState(null);
    const [currentQorb, setCurrentQorb] = useState(null);
    const [allQorbs, setAllQorbs] = useState(null);
    const { devicedUsed } = useSphereOneProvider();

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const qorbId = urlParams.get('qorb');
    const qorbWallet = urlParams.get('wallet');

    const getQorbData = async () => {
        const client = await ImmutableXClient.build({
            publicApiUrl: "https://api.x.immutable.com/v1",
        });
        if (qorbWallet !== undefined) {
            const qorbAssetsRequest = await client.getAssets({
                user: qorbWallet,
                collection: "0x2d59c7b8d253b826cdba9389be397a68af88cf08",
            });
            if (!qorbAssetsRequest.result.length > 0) {
                navigate('/my-assets');
            } else {
                setAllQorbs(qorbAssetsRequest.result)
                const filteredQorb = qorbAssetsRequest.result.find(token => token.token_id === qorbId);
                if (!filteredQorb) {
                    navigate('/my-assets');
                } else {
                    fetchData()
                    setCurrentQorb(filteredQorb);
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`https://api.qorbiworld.com/stake/get-stake-qorbs/${qorbWallet}`);
            setQorbStaking(data);
        } catch (error) {
            console.error('Error fetching QorX data:', error);
        }
    };

    useEffect(() => {
        getQorbData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMobileView = () => {
        return (
            <div style={{ padding: '20px', marginTop: 50, textAlign: 'center', fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                {qorbStaking && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                                src={currentQorb?.image_url}
                                alt="QorX"
                                style={{
                                    width: 'auto',
                                    height: '40vh',
                                    border: '5px solid #2DD881',
                                    borderRadius: '15px',
                                    marginBottom: '20px',
                                }}
                            />
                            <div style={{ color: 'white', fontWeight: 'bold' }}>

                                <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                    {`${currentQorb?.name} #${qorbId}`}
                                </div>
                                {Object.keys(qorbStaking?.groups).length > 0 &&
                                    <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                        Qorbs Owned: {Object.keys(qorbStaking?.groups)?.length}
                                    </div>
                                }
                                <br></br>
                                <div className='total-qorbi'>
                                    <img
                                        src={qorbi_coin}
                                        alt="Qorbi_Coin"
                                        style={{
                                            width: 'auto',
                                            height: '50px',
                                        }}
                                    />
                                    ‎ Qorbi Earned: {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '50px', marginRight: '20px', color: "white" }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Staking</h3>
                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'start' }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                }}>
                                    <div style={{ flex: 1 }}></div>
                                    <div style={{ flex: 4 }}>Date</div>
                                    <div style={{ flex: 2 }}>Token ID</div>
                                </div>
                                {qorbStaking?.groups[qorbId]?.length > 0 && qorbStaking.groups[qorbId].slice().reverse().map((row) => (
                                    <div key={row.tokenId} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{ flex: 4 }}>{row.day}</div>
                                        <div style={{ flex: 2 }}>{row.tokenId}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderComputerView = () => {
        return (
            <div style={{ display: 'flex', marginTop: 75, marginLeft: 75, fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                <div style={{ flex: '2', padding: '20px' }}>
                    {qorbStaking && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img
                                    src={currentQorb?.image_url}
                                    alt="QorX"
                                    style={{
                                        width: 'auto',
                                        height: '40vh',
                                        border: '5px solid #2DD881',
                                        borderRadius: '15px',
                                        marginRight: '20px',
                                    }}
                                />
                                <div>
                                    <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                        {`${currentQorb?.name} #${qorbId}`}
                                    </div>
                                    {Object.keys(qorbStaking?.groups).length > 0 &&
                                        <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                            Qorbs Owned: {Object.keys(qorbStaking?.groups)?.length}
                                        </div>
                                    }
                                    <br></br>
                                    <div style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '34px',
                                        marginBottom: '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        backgroundColor: '#1A1919',
                                        borderRadius: '30px',
                                        padding: '15px'
                                    }}>
                                        <img
                                            src={qorbi_coin}
                                            alt="Qorbi_Coin"
                                            style={{
                                                width: 'auto',
                                                height: '50px',
                                            }}
                                        />
                                        ‎ Qorbi Earned: {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                                    </div>
                                </div>
                            </div>
                            <div style={{ color: 'white', marginTop: 25 }}>
                                <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Staking</h3>
                                <div style={{ width: '100%', marginTop: '10px' }}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                    }}>
                                        <div style={{ flex: 1, paddingLeft: '100px' }}>Date</div>
                                        <div style={{ flex: 1, paddingLeft: '100px' }}>Token ID</div>
                                        <div style={{ flex: 1, paddingLeft: '100px' }}>Staking ID</div>
                                    </div>
                                    {qorbStaking?.groups[qorbId]?.length > 0 && qorbStaking.groups[qorbId].slice().reverse().map((row) => (
                                        <div key={row._id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>{row.day}</div>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>{row.tokenId}</div>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>{row._id}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {allQorbs?.length > 0 && (
                    <div style={{ flex: '1', padding: '20px', marginTop: '25px', marginRight: '75px' }}>
                        <div style={{ color: 'white', marginTop: 25 }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>Staking Qorbs</h3>
                            <div style={{ width: '100%', margin: '0 auto', marginTop: '10px', overflowX: 'hidden' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    background: "#1A1919",
                                    border: "1px solid #FFFFFF0D",
                                    borderRadius: "25px",
                                    opacity: "1",
                                    padding: "10px"
                                }}>
                                    <div style={{ flex: 4, paddingLeft: "30px" }}>Name</div>
                                    <div style={{ flex: 2 }}>ID</div>
                                </div>
                                {allQorbs.map((row) => (
                                    <div key={row.token_id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 4, paddingLeft: "30px" }}>{row.name}</div>
                                        <div style={{ flex: 2 }}>{row.token_id}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
                }
            </div >
        );
    };

    return devicedUsed === 'mobile' ? renderMobileView() : renderComputerView();
};

export default QorbStaking;